import {
  ref, onMounted, getCurrentInstance, reactive, watch
} from 'vue'
import { useStore } from 'vuex'
import { receiveItemUseCase, getListAgent } from '@/domain/usecase'
import moment from 'moment'

export default {
  setup() {
    const app = getCurrentInstance()
    const {
      $numbers, $toast, $strInd, $confirm
    } = app!.appContext.config.globalProperties
    const inputSearch = ref('')
    const data = ref([])
    const listAgent = ref([]) as any
    const store = useStore()
    const toAgentId = ref(store.getters['appActiveUser/getAgent'].id)
    const selectValue: any = ref([])
    const selectOption = [
      { name: 'RIT 1', value: '1' },
      { name: 'RIT 2', value: '2' }
    ]
    const filters = reactive({
      skip: 0,
      top: $numbers.totalItemPerPage,
      totalRecords: 0,
      search: '',
      fromAgentId: { label: 'Semua Agen', value: null },
      dateFilter: null,
      defaultfilter: `AgenTujuanId eq ${toAgentId.value} and StatusPengirimanId in (4,5,6)`,
      filter: '',
      select: 'Id,Resi,StatusPengirimanId,ManifestId,AgenAsalId,AgenTujuanId,NamaPengirim,NamaPenerima,TipePengiriman',
      expand: `StatusPengiriman,TransJadwalHd($expand=TransJadwalDetails($filter= agenId eq ${toAgentId.value} ;$select=WaktuKedatangan);$select=id,KodeJadwal,TanggalAktif,Rit;),AgenAsal($select=Nama),
      AgenTujuan($select=Nama),TransPengirimanDetails($count=true;$select=id)`
    })
    const agentOp = ref()
    const arrivalOp = ref()
    const listChecked = ref([]) as any

    const getAgents = async () => {
      const response = await getListAgent()
      if (!response.error) {
        listAgent.value = response.result.map((item: any) => ({
          label: (item.Kota) ? `(${item.Kota.Kode}) ${item.Kota.Nama}` : item.Nama,
          value: item.Id
        }))
      }
      listAgent.value.unshift({ label: 'Semua Agent', value: null })
    }
    const getFilterRit = () => {
      const isValid = selectValue.value.length > 0
      if (isValid) {
        const rest = selectValue.value.map((d: any) => `'${d}'`)
        return `and TransJadwalHd/Rit in (${rest.join(',')})`
      }
      return ''
    }
    const getAllData = async (search: any = null) => {
      store.dispatch('showLoading')
      filters.search = search
      if (search !== null) {
        filters.skip = 0
      }
      filters.filter = `${filters.defaultfilter} ${getFilterRit()}`
      const response = await receiveItemUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        filters.totalRecords = response.count
        data.value = response.result.map((item: any) => {
          item.checked = listChecked.value.filter((val: any) => val === item.Id).length === 1
          return item
        })
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      getAllData(search)
    }

    const showDropdownFromAgent = (evt: any) => {
      agentOp.value.toggle(evt)
    }

    const showDropdownArrival = (evt: any) => {
      arrivalOp.value.toggle(evt)
    }

    const selectedAgent = (evt: any, item: any) => {
      filters.fromAgentId = item
      agentOp.value.toggle(evt)
      getAllData(filters.search)
    }

    const saveFilterDate = (evt: any) => {
      // console.log('saveFilterDate', filters.dateFilter)
      arrivalOp.value.toggle(evt)
      getAllData(filters.search)
    }

    const clearFilterDate = (evt: any) => {
      filters.dateFilter = null
      arrivalOp.value.toggle(evt)
      getAllData(filters.search)
    }

    const onPage = (event: any) => {
      if (filters.search === '' || filters.search === null) {
        filters.skip = event.page * filters.top
        getAllData()
      }
    }

    const changeCheckbox = (item: any, checked: any) => {
      if (item.StatusPengirimanId > 4 && checked) {
        item.checked = false
        $toast.add({
          severity: 'error', detail: 'Mohon maaf, anda hanya dapat memilih RESI yang berstatus berangkat', group: 'bc', life: 3000
        })
      } else if (checked) {
        listChecked.value.push(item.Id)
      } else {
        const index = listChecked.value.indexOf(item.Id)
        if (index > -1) {
          listChecked.value.splice(index, 1)
        }
      }
    }

    watch([selectValue], ([newValueSelect], [prevValueSelect]) => {
      if (selectValue.value) {
        getAllData()
      }
    })

    const proccessDropAgent = async () => {
      store.dispatch('showLoading')
      const response = await receiveItemUseCase.submitDropAgent(listChecked.value)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
      } else {
        listChecked.value = []
        $toast.add({
          severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
        })
        getAllData()
      }
    }

    const submitDropAgent = () => {
      $confirm.require({
        header: 'Diterima Agen',
        message: 'Apakah anda sudah mengecek kembali pilihan resi anda? Jika sudah diproses data tidak dapat dibatalkan',
        icon: 'pi pi-info-circle',
        accept: async () => {
          proccessDropAgent()
        }
      })
    }

    onMounted(() => {
      getAgents()
      getAllData()
    })
    return {
      data,
      moment,
      inputSearch,
      toAgentId,
      filters,
      agentOp,
      arrivalOp,
      listAgent,
      listChecked,
      changeCheckbox,
      onPage,
      selectedAgent,
      getAllData,
      searchData,
      showDropdownFromAgent,
      showDropdownArrival,
      clearFilterDate,
      saveFilterDate,
      submitDropAgent,
      selectValue,
      selectOption
    }
  }
}
